import { useEffect, useState, useRef } from "react";
import ProgressBar from "./ProgressBar";
import '../../css/story.css';
import TextPost from './TextPost';
import ImagePost from './ImagePost';

const InnerStory = ({ data }) => {

    const handleClick = (id) =>{
        const post = document.getElementById(id);
        post.scrollIntoView({behavior: 'smooth'});
    }
    
    return (
            <div className="story-inner-container">
                <div style={{ 
                        height: `${data.scroll}%`,
                        borderRight: '5px solid white',
                        zIndex: '2',
                    }}
                    className="progress-bar" />
                <div className="icons-container">
                    <div onClick={() => handleClick(2)} className={data.scroll < 14.6 ? `icon1 icon not-active` : `icon1 icon`}/>
                    <div onClick={() => handleClick(3)} className={data.scroll < 29.3 ? `icon2 icon not-active` : `icon2 icon`}/>
                    <div onClick={() => handleClick(4)} className={data.scroll < 43.8 ? `icon3 icon not-active` : `icon3 icon`}/>
                    <div onClick={() => handleClick(5)} className={data.scroll < 58.5 ? `icon4 icon not-active` : `icon4 icon`}/>
                    <div onClick={() => handleClick(6)} className={data.scroll < 73.2 ? `icon5 icon not-active` : `icon5 icon`}/>
                </div>
                <div className='post-container'>
                    {data.story && data.story.length > 0 ? (
                        data.story.map((post, index) => {
                            if (Array.isArray(post.images) && post.images.length > 0) {
                                return <ImagePost key={index} data={post} id={post.postID}/>;
                            } else {
                                return <TextPost key={index} data={post} />;
                            }
                        })
                    ) : (
                        <p>No posts available</p>
                    )}
                </div>
            </div>
    );
    
}

export default InnerStory;

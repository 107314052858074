import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import { throttle } from 'lodash';
import '../../css/story.css';
import InnerStory from "./InnerStory";
import { fetchStory } from '../../store/reducers/storySlice';

const Story = () => {
    const dispatch = useDispatch();
    const [scroll, setScroll] = useState(0);
    const scrollRef = useRef(null);

    useEffect(() => {
        const asyncFetchData = async () => {
            try {
                await Promise.all([dispatch(fetchStory())]);
            } catch (e) {
                console.error(e);
            }
        };
        asyncFetchData();
    }, [dispatch]);

    useEffect(() => {
        const innerContainer = scrollRef.current;

        const throttledHandleScroll = throttle(() => {
            if (innerContainer) {
                const scrollableHeight = innerContainer.scrollHeight - innerContainer.clientHeight;
                const scrollProgress = (innerContainer.scrollTop / scrollableHeight) * 100;
                setScroll(scrollProgress);
            }
        }, 100);

        if (innerContainer) {
            innerContainer.addEventListener('scroll', throttledHandleScroll);
        }

        return () => {
            if (innerContainer) {
                innerContainer.removeEventListener('scroll', throttledHandleScroll);
            }
            throttledHandleScroll.cancel();
        };
    }, []);

    const { story } = useSelector((state) => ({
        story: state.story.story,
    }));

    return (
        <div className="story-container" ref={scrollRef}>
            <InnerStory data={{ story, scroll }} />
        </div>
    );
};

export default Story;
